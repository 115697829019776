<template>
  <div class="pd-20">
    <a-page-header class="pd-0 mb-20">
      <template slot="title">
        <h1 class="iblock relative top5">
          <img
            src="@/assets/images/dashboard/products/products-img.png"
            alt="img"
            width="30"
          />
          Orçamento de Produtos
        </h1>

        <span class="ml-40 relative iblock">
          <a-input
            class="search-field travel-input"
            placeholder="Nome do produto + Enter"
            v-model="product.filters.name"
            @pressEnter="onPressEnterGetProducts"
            style="width: 300px"
          >
            <a-icon style="f16" slot="prefix" type="search" />
          </a-input>
        </span>
      </template>

      <template slot="extra">
        <a-button type="primary" ghost @click="onClickOpenGenerateBudgetModal">
          Gerar orçamento
        </a-button>
      </template>
    </a-page-header>

    <a-table
      class="travel-table"
      :columns="productBudgetTableColumns"
      :data-source="product.list"
      :loading="product.loading"
      :pagination="false"
      :scroll="{ x: 1300 }"
      @change="productTableChange"
    >
      <div slot="value" slot-scope="record">
        {{ record.currency | formatCurrency }} {{ record.value | formatPrice }}
      </div>
      <div slot="customer" slot-scope="record">
        <div v-if="record?.customer?.id">
          <div v-if="record.customer?.person_type === 'Pessoa Física'">
            {{ record.customer.id }} - {{ record.customer.first_name }}
            {{ record.customer.last_name }}
          </div>
          <div v-if="record.customer?.person_type === 'Pessoa Jurídica'">
            {{ record.customer.id }} - {{ record.customer.trading_name }}
          </div>
        </div>
      </div>
      <div slot="user" slot-scope="record">
        <a-tooltip>
          <template slot="title">
            {{ record.user.first_name }} {{ record.user.last_name }}
          </template>
          <span id="avatar">
            <a-avatar
              v-if="record.user.avatar"
              :src="record.user.avatar"
              :size="30"
            />
            <a-avatar
              v-else
              class="upper"
              style="color: #f56a00; background-color: #fde3cf"
            >
              {{ record.user.first_name.substring(0, 1)
              }}{{ record.user.last_name.substring(0, 1) }}
            </a-avatar>
          </span>
        </a-tooltip>
      </div>

      <div class="travel-actions" slot="action" slot-scope="record">
        <a-tooltip placement="top" title="Editar">
          <a
            class="edit"
            @click="$router.push(`/products-budget/edit/${record.id}`)"
          >
            <a-icon type="edit-svg" />
          </a>
        </a-tooltip>

        <a-tooltip
          v-if="record.status === 'published'"
          placement="top"
          title="Ver"
        >
          <a
            class="view ml-15"
            @click="$router.push(`/products-budget/view/${record.id}`)"
          >
            <a-icon slot="prefix" :type="'eye-svg'" />
          </a>
        </a-tooltip>
      </div>
    </a-table>

    <div class="a-center mt-30">
      <a-pagination
        show-size-changer
        :default-current="product.pagination.page"
        :page-size.sync="product.pagination.perPage"
        :total="product.pagination.total"
        @change="changeProductPage"
        @showSizeChange="productChangePageSize"
      >
      </a-pagination>
    </div>

    <a-modal
      v-model="openGenerateBudgetModal"
      title="Gerar orçamento"
      :width="400"
      :footer="false"
      @cancel="openGenerateBudgetModal = false"
    >
      <GenerateBudgetModal
        v-if="openGenerateBudgetModal"
        @onGenerateProductBudget="onGenerateProductBudget"
      />
    </a-modal>
  </div>
</template>

<script>
// components
import GenerateBudgetModal from "@/components/products/modals/GenerateBudgetModal.vue";

// reuse
import productReusables from "@/components/products/mixins/productReusables.js";
import reusableThings from "@/mixins/general/reusableThings.js";

export default {
  components: { GenerateBudgetModal },
  name: "ProductsBudgetList",
  mixins: [productReusables, reusableThings],
  data() {
    return {
      openGenerateBudgetModal: false,
      productBudgetTableColumns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          class: "id",
          width: 65,
          sorter: true,
        },
        {
          title: "Nome",
          dataIndex: "name",
          key: "name",
          class: "name",
          width: 300,
          sorter: true,
        },
        {
          title: "Valor",
          key: "value",
          width: 120,
          sorter: true,
          scopedSlots: { customRender: "value" },
        },
        {
          title: "Cliente",
          width: 150,
          scopedSlots: { customRender: "customer" },
        },
        {
          title: "Usuário",
          width: 150,
          scopedSlots: { customRender: "user" },
        },
        {
          title: "",
          key: "action",
          align: "right",
          width: 90,
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
      responsiveBoxes: [
        {
          min: 0,
          max: 599,
          span: 24,
        },
        {
          min: 600,
          max: 899,
          span: 12,
        },
        {
          min: 900,
          max: 1199,
          span: 8,
        },
        {
          min: 1200,
          max: 1599,
          span: 6,
        },
        {
          min: 1600,
          max: 9999,
          span: 4,
        },
      ],
    };
  },
  async created() {
    this.isBudget = true;
    await this.getProducts();
  },
  methods: {
    onClickOpenGenerateBudgetModal() {
      this.openGenerateBudgetModal = true;
    },
    onGenerateProductBudget() {
      this.openGenerateBudgetModal = false;
      this.getProducts();
    },
    onPressEnterGetProducts() {
      this.product.pagination.page = 1;
      this.getProducts();
    },
  },
};
</script>
